import {isMobile} from 'react-device-detect';
import './Brand.css'
import backgroundM from './bg_1.jpg'
import backgroundD from './bg_1.jpg'
function device() {
  if (isMobile) {
    return backgroundM
  } else {
    return backgroundD
  }
}

function Brand() {
    return (
      <div id="brand">
        <div className='left'>
          <div>
              <p className='georgia'>Независимый бренд косметики для ухода за кожей</p>
              <p>
              Мягкий смарт-уход с рабочими ингредиентами и минималистичными составами. 
              <br></br>Формулы разрабатываются в соответствии с современными трендам ухода за кожей. 
              <br></br>
              <br></br>
              Не тестируем средства на животных и не используем ингредиенты животного происхождения.

              </p>
              {/* <button>
                Подробнее
              </button> */}
            </div>  
        </div>
        {/* <div className='right' style={{ backgroundImage: `url(${device()})` }}>
        </div> */}
      </div>
    );
  }
  
  export default Brand;