import {isMobile} from 'react-device-detect';
import { useLocation } from "react-router-dom";
import './Qr.css'
import logo from './fromthemoon.svg'
import logoSmall from './ftm.svg'
import girl from './girl.png'
import tg from './tg.svg'
import Products from '../Products/Products'
import Brand from '../Brand/Brand'
import Product from '../Product/Product'


import {
  Link
} from "react-router-dom";

function Qr() {
  const page = useLocation().pathname
  if (page==="/Catalogue") {
      return (
        <div id="qr">
          <Header  height="30%" image={logoSmall} image2={girl} width='70'/>
          <Products />
        </div>
      );
  } else if (page==="/Stores") {
    return (
      <div id="qr">
        <Header  height="30%" image={logoSmall} image2={girl} width='70'/>
        <Products type='stores'/>
      </div>
    );
  } else if (page==="/Brand") {
    return (
      <div id="qr">
        <Header  height="30%" image={logoSmall} image2={girl} width='70'/>
        <Brand />
      </div>
    );
  } else if (page.includes('/3')) {
    return (
      <div id="qr">
        <Product />
      </div>
    );
  } 
  else {
    return (
      <div id="qr">
        <Header height="50%" image={logo} elipse='elipse' width='55'/>
        <Menu />
        <Footer />
      </div>
    );
  }
}

function Header(props) {
  return (
    <Link to="/">      
      <div class="profile" style={{"height": `${props.height}` }}>
        <img className='fromthemoon' src={props.image} style={{"width": `${isMobile ? props.width : props.width/4}vw`}}></img>
        {props.height === '30%' && 
        <img className='girl' src={props.image2}></img>
        }
        <div className={props.elipse}></div>
      </div>
    </Link>
);
}
function Menu() {
  return (      
  <div className='menu'>
    <Link className='menuLink' to='/Catalogue'>Каталог</Link>
    <Link className='menuLink' to='/Brand'>О бренде</Link>
    <Link className='menuLink' to='/Stores'>Где купить</Link>
    <Link className='menuLink' to='https://t.me/ftmoonBot'>Написать в поддержку</Link>
  </div>
);
}
function Footer() {
  return (
    <div className='telegram'>
      <img src={tg} className='logo'></img>
      <a href='https://t.me/from_themoon'>Узнавайте наши новости в Telegram</a>
    </div>  
  );
}

export default Qr;