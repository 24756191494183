import {useEffect, useState} from 'react';
import {BrowserView} from 'react-device-detect';
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import './Product.css'
import Back from '../Back'
import {pages} from './Pages'
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function Products() {
    const [isShown, setIsShown] = useState(false);

    const handleClick = event => {
      // 👇️ toggle shown state
      setIsShown(current => !current);
    };
    const sku = useLocation().pathname
    return (
      <div>
        <BrowserView>
        <Back />
        </BrowserView>
        <ScrollToTop />
        <div id='product'>
            <div className='right'>
                <img alt={pages[sku]['image']} src={pages[sku]['image']}></img>
            </div>  
            <div className='left'>
                <div>
                    <h2>{pages[sku]['title']}</h2>
                    <p className='volume'>{pages[sku]['volume']}</p>
                    <p>{parse(pages[sku]['text'])}</p>
                    <p>
                        Не тестируется на животных и не содержит компоненты животного происхождения.
                    </p>
                    <p className='partHeadline'><b>Способ применения:</b></p>
                    <p>{pages[sku]['usage']}</p>
                      <p className='partHeadline'>
                          <b>Заказать на:</b>
                      </p>
                    <div className='mpLinks'>
                      <a href={pages[sku]['ozLink']} style={{background: '#005BFF'}}>Ozon</a>
                      <a href={pages[sku]['wbLink']} style={{background: 'linear-gradient(90deg, #BE11A5 0%, #BE11A5 100%)'}}>Wildberries</a>
                      <a href={pages[sku]['gaLink']} style={{background: '#DCFF00', color: 'black'}}>Золотое Яблоко</a>
                      <a href={pages[sku]['ltLink']}style={{background: '#000000'}} >Лэтуаль</a>
                    </div>
                    </div>
                </div>
                    <div className='telegram'>
                      {!isShown && (
                      <>
                        <a onClick={handleClick} className='ingredients'>Развернуть состав</a>
                      </>
                    )}
                      {isShown && (
                          <p className='inci'>
                            <b>Состав (INCI): </b>
                            {pages[sku]['ingredients']}
                            <br></br>
                          </p>
                      )}
                      {isShown && ( <a onClick={handleClick} className='ingredients'>Свернуть состав</a>)}
            </div>
        </div>
      </div>
    );
  }
  
  export default Products;