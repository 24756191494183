import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Link
} from "react-router-dom";
import React, {useRef } from 'react';
import './Products.css'

import {pages} from '../Product/Pages'
import {stores} from '../Product/Stores'


// function device() {
//   if (isMobile) {
//     return backgroundM
//   } else {
//     return backgroundD
//   }
// }

const ImageToggleOnMouseOver = ({primaryImg, secondaryImg}) => {
  const imageRef = useRef(null);

  return (
    <img
      onMouseOver={() => {
        imageRef.current.src = secondaryImg;
      }}
      onMouseOut={() => {
        imageRef.current.src= primaryImg;
      }}
      src={primaryImg} 
      alt=""
      ref={imageRef}
    />
  )
}

function Products(props) {
  if (props.type==='stores'){
    var obj = stores
  } else {
    var obj = pages
  }
  console.log(obj)
  console.log(Object.entries(obj));
    return (
      <div id="products">
        <ThemeProvider
          theme={createTheme({
            breakpoints: {
              values: {
                laptop: 1024,
                tablet: 640,
                mobile: 0,
                desktop: 1280,
              },
            },
          })}
        >
            <Grid container spacing={{ mobile: 2, laptop: 4 }}>

              {Object.entries(obj).map(function(object, i){
                  return (
                    <Grid key={`products_${i}`} mobile={6} laptop={4}>
                      <Link to={props.type === "stores" ? object[1].link : object[0]}>
                        <div className='imageBg'>
                          <ImageToggleOnMouseOver
                                primaryImg={object[1].preview1}
                                secondaryImg={object[1].preview2}
                                alt={object[1].title} 
                          >
                          </ImageToggleOnMouseOver>
                        </div>
                        <h3>{object[1].title}</h3>
                        <p className='georgia'>{object[1].description}  </p>
                      </Link>
                    </Grid>
                  )
              })}
            </Grid>
          </ThemeProvider>
      </div>
    );
  }
  
  export default Products;