function importAll(r) {
  let images = {};
  r.keys().map(item => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('.', false, /\.(png|jpe?g|svg)$/));
console.log(images);
export const screens = [{
    title:`Невесомый натуральный дезодорант`,
    text: "Борется с причиной неприятного запаха. Совершенно не ощущается на коже и не оставляет следов на одежде.",
    link: '/300',
    image: images['bg_1.jpg']
  },
  // {
  //   title:`ГЛУБОКИЙ УХОД ЗА МИКРОБИОМОМ КОЖИ`,
  //   text: 'Пребиотики поддерживают "хорошие" бактерии на коже. Увлажняет и нормализует состояние кожи.',
  //   link: '/302',
  //   image: images['tonic.jpg']
  // },
  {
    title:`Лёгкий крем увлажнение и питание`,
    text: 'Нормализует состояние кожи. Обладает противовоспалительными свойствами. Быстро впитывается без липкости.',
    link: '/303',
    image: images['cream.jpg']
  }]