import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom"; 
import Header from './Header/Header'
import Products from './Products/Products'
import Brand from "./Brand/Brand";
import Photos from "./Photos/Photos";
import Footer from "./Footer/Footer";
import Stores from "./Stores/Stores";
import Qr from "./qr/qr";
import {isMobile} from 'react-device-detect';

function App() {
  return (
    <Router>
      <Routes>
          <Route key='route_1' path="*" element={<Qr />}/>
      </Routes>
    </Router>
  );
}


function Main() {
  return (
    <div>      
      <Header />
      <Brand />
      <Photos />
      <Products />
      <Stores />  
      <Footer />
    </div>
  );
}

export default App;
