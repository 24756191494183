import {isMobile} from 'react-device-detect';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import './Photos.css'
function importAll(r) {
  return r.keys().map(r);
}
function device() {
  if (isMobile) {
    return [2,200]
  } else {
    return [4,400]
  }
}

const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));
function Photos() {
    return (
      <div id="images">
        <ImageList sx={{ width: "auto", height: "auto" }} gap={0} cols={device()[0]} rowHeight={'auto'} style={{marginBlockEnd: '0px', marginBlockStart: '0px'}}>
          {images.map(function(object, i){
            let style = {
              height:device()[1],
              objectPosition: 'top'
            }
              return (
                <ImageListItem key={object}>
                <img
                  src={object}
                  alt={i}
                  loading="lazy"
                  style={style}
                />
              </ImageListItem>
              )
          })}
        </ImageList>
      </div>
    );
  }
  
  export default Photos;