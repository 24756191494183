function importAll(r) {
  let images = {};
  r.keys().map(item => { images[item.replace('./', '')] = r(item); 
  return r;
});
  return images;
}

const images = importAll(require.context('.', false, /\.(png|jpe?g|svg)$/));
const preview = importAll(require.context('./Preview', false, /\.(png|jpe?g|svg)$/));

export const pages = {
  '/300': {
    title:`AHA-Deodorant`,
    description: 'Миндальная кислота и гель алоэ-вера',
    text: `<b>Новый подход:</b> создаёт среду, в которой не выживают бактерии, вызывающих запах. 
            Не забивает поры и не мешает естественному процессу потоотделения. 
            <br /><b>Без дискомфорта:</b> не ощущается на коже и не оставляет следов на одежде. Обладает лёгким свежим ароматом.
            <br /><b>Ухаживает за кожей: </b>увлажняет и питает кожу подмышек.`,
    usage: 'Нанести на чистую сухую кожу подмышек с расстояния 10-15 см. Достаточно 2-3 пшиков на каждую подмышку.',
    ingredients: 'Aqua, Glycerin, Hydroxyethyl Urea, Panthenol, Citric Acid, Sodium Hyaluronate, AllInulin, Alpha-glucanoligosaccharide, Vitis vinifera (grape) seed oil, antoin, Niacinamide, Caffeine, Benzyl Alcohol, Chlorphenesin.',
    image: images['deo.png'],
    volume: '100 мл',
    preview1: preview['300.png'],
    preview2: preview['deo_2.png'],
    ozLink: "https://ozon.ru/context/detail/id/202442712",
    wbLink: "https://www.wildberries.ru/catalog/166383388/detail.aspx",
    gaLink: "https://goldapple.ru/19000190373-natural-aluminum-free",
    ltLink: "https://www.letu.ru/product/fromthemoon-naturalnyi-aha-dezodorant-bez-alyuminiya-ph-3-6/150800115",
  },
  '/302': {
    title:`Биом-тоник`,
    description: 'Пребиотики, кофеин, масло виноградной косточки',
    text: `Поддерживает естественный барьер кожи: <b>микробиом.</b> 
      <br />Здоровый микробиом защищает кожу от инфекций и воспалений, снижает вероятность появления покраснений и акне. 
      <br />Тоник увлажняет, питает и нормализует состояние кожи.
      <br />Нейтральный рН 5,5 помогает сбалансировать кислотность кожи после умывания.
      <br />Подходит для чувствительной кожи. 
      Не содержит отдушек, эфирных масел и красителей.`,
    usage: 'Нанести на чистую кожу с помощью ватного диска или рук.',
    ingredients: 'Aqua, Glycerin, Hydroxyethyl Urea, Panthenol, Citric Acid, Sodium Hyaluronate, AllInulin, Alpha-glucanoligosaccharide, Vitis vinifera (grape) seed oil, antoin, Niacinamide, Caffeine, Benzyl Alcohol, Chlorphenesin.',
    image: images['tonic.png'],
    volume: '100 мл',
    preview1: preview['302.png'],
    preview2: preview['tonic_2.png'],
    ozLink: "https://ozon.ru/context/detail/id/256454292",
    wbLink: "https://www.wildberries.ru/catalog/166391642/detail.aspx",
    gaLink: "https://goldapple.ru/19000190374-hydrating-biome-tonic",
    ltLink: "https://www.letu.ru/product/fromthemoon-uvlazhnyayushchii-myagkii-biom-tonik-dlya-litsa-s-prebiotikami-i-niatsinamidom-ph-5-5/150800117",
  },
  '/303': {
    title:`Лёгкий крем увлажнение и питание`,
    description: 'Ниацинамид, масло жожоба и зелёный чай',
    text: `Увлажняет, питает и защищает кожу в течение всего дня. 
          <br />Помогает нормализовать состояние кожи и сделать её мягкой. 
          <br />Быстро впитывается и не оставляет липкости. 
          <br />Нейтральный pH 5.5 помогает сбалансировать кислотность кожи.
          <br  />Подходит для чувствительной кожи. 
          Не содержит отдушек, эфирных масел и красителей.
          `,
    usage: 'Нанести на чистую кожу небольшое количество крема массажными движениями. Для зоны лица и шеи достаточно 2-3 нажатий дозатора.',
    ingredients: 'Aqua, Caprylic/Capric Triglyceride, Polyglyceryl-3 Methylglucose Distearate, Glycerin, C12-15 Alkyl Benzoate, Simmondsia Chinensis (Jojoba) Seed Oil, Glyceryl Stearate, Niacinamide, Cetearyl Alcohol, Phenoxyethanol, Ethylhexylglycerin, Camellia Sinensis Leaf Extract, Polyacrylamide, C13-14 Isoparaffin, Laureth-7, Xanthan Gum, Tocopheryl Acetate, Sodium Hyaluronate, Tocopherol, Beta-Sitosterol, Squalane, Citric Acid.',
    image: images['cream.png'],
    volume: '50 мл',
    preview1: preview['303.png'],
    preview2: preview['cream_2.png'],
    ozLink: "https://ozon.ru/context/detail/id/257071118",
    wbLink: "https://www.wildberries.ru/catalog/166415744/detail.aspx",
    gaLink: "https://goldapple.ru/19000190375-moisturising-and-nourishing",
    ltLink: "https://www.letu.ru/product/fromthemoon-lyogkii-krem-dlya-litsa-uvlazhnenie-i-pitanie-s-maslom-zhozhoba-i-niatsinamidom-ph-5-5/150800116",
  },
  '/301': {
    title:`Очищающий крем-гель`,
    description: 'Комплекс мягких ПАВ, салициловая кислота',
    text: `Бережно очищает, не нарушая естественный защитный барьер кожи и не оставляя сухости. Увлажняет, смягчает и освежает кожу. 
          <br />Не содержит отдушек, сульфатов, эфирных масел и красителей.
          <br />Нейтральный pH 5.5 помогает сбалансировать кислотность кожи.
          <br  />Подходит для чувствительной кожи.
          `,
    usage: 'Вспенить крем-гель, массажными движениями нанести на кожу лица, смыть водой.',
    ingredients: 'Aqua, Disodium EDTA, Glycerin, Potassium Laureth-4 Carboxylate, Cocamidopropyl Betaine, PEG-4 Rapeseedamide, Glycereth-2 Cocoate, Allantoin, Salicylic Acid, Salvia Officinalis Extract, Phenoxyethanol, Ethylhexylglycerin, PEG-7 Glyceryl Cocoate, PEG-200 Hydrogenated Glyceryl Palmate.',
    image: images['cleanser.png'],
    volume: '200 мл',
    preview1: preview['301.png'],
    preview2: preview['301_2.png'],
    ozLink: "https://ozon.ru/context/detail/id/1039029816",
    wbLink: "https://www.wildberries.ru/catalog/166417968/detail.aspx",
    gaLink: "https://goldapple.ru/19000190376-soft-cleansing",
    ltLink: "https://www.letu.ru/product/fromthemoon-myagkii-krem-gel-dlya-umyvaniya-i-snyatiya-makiyazha-s-salitsilovoi-kislotoi-ph-5-5/150800118",
  }
}