import {
  Link
} from "react-router-dom";

function Back() {
    return (
      <Link to='/Catalogue'>
        <div id="back">
          {'<'} Назад
        </div>
      </Link>
    );
  }
  
export default Back;