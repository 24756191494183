function importAll(r) {
  let images = {};
  r.keys().map(item => { images[item.replace('./', '')] = r(item); 
  return r;
});
  return images;
}

const preview = importAll(require.context('./Stores', false, /\.(png|jpe?g|svg)$/));

export const stores = {
  '/ozon': {
    title:`Ozon`,
    text: `Поддерживает естественный барьер кожи: <b>микробиом.</b> 
      <br />Здоровый микробиом защищает кожу от инфекций и воспалений, снижает вероятность появления покраснений и акне. 
      <br />Тоник увлажняет, питает и нормализует состояние кожи.
      <br />Нейтральный рН 5,5 помогает сбалансировать кислотность кожи после умывания.
      <br />Подходит для чувствительной кожи. 
      Не содержит отдушек, эфирных масел и красителей.`,
    usage: 'Нанести на чистую кожу с помощью ватного диска или рук.',
    ingredients: 'Aqua, Glycerin, Hydroxyethyl Urea, Panthenol, Citric Acid, Sodium Hyaluronate, AllInulin, Alpha-glucanoligosaccharide, Vitis vinifera (grape) seed oil, antoin, Niacinamide, Caffeine, Benzyl Alcohol, Chlorphenesin.',
    // image: images['tonic.png'],
    volume: '100 мл',
    preview1: preview['ozon.png'],
    preview2: preview['ozon.png'],
    link: "https://www.ozon.ru/seller/fromthemoon-92372"
  },
  '/wildberries': {
    title:`Wildberries`,
    text: `Увлажняет, питает и защищает кожу в течение всего дня. 
          <br />Помогает нормализовать состояние кожи и сделать её мягкой. 
          <br />Быстро впитывается и не оставляет липкости. 
          <br />Нейтральный pH 5.5 помогает сбалансировать кислотность кожи.
          <br  />Подходит для чувствительной кожи. 
          Не содержит отдушек, эфирных масел и красителей.
          `,
    usage: 'Нанести на чистую кожу небольшое количество крема массажными движениями. Для зоны лица и шеи достаточно 2-3 нажатий дозатора.',
    ingredients: 'Aqua, Caprylic/Capric Triglyceride, Polyglyceryl-3 Methylglucose Distearate, Glycerin, C12-15 Alkyl Benzoate, Simmondsia Chinensis (Jojoba) Seed Oil, Glyceryl Stearate, Niacinamide, Cetearyl Alcohol, Phenoxyethanol, Ethylhexylglycerin, Camellia Sinensis Leaf Extract, Polyacrylamide, C13-14 Isoparaffin, Laureth-7, Xanthan Gum, Tocopheryl Acetate, Sodium Hyaluronate, Tocopherol, Beta-Sitosterol, Squalane, Citric Acid.',
    // image: images['cream.png'],
    volume: '50 мл',
    preview1: preview['wildberries.png'],
    preview2: preview['wildberries.png'],
    link: "https://www.wildberries.ru/seller/129940"
  },
  '/ga': {
    title:`Золотое яблоко`,
    text: `<b>Новый подход:</b> создаёт среду, в которой не выживают бактерии, вызывающих запах. 
            Не забивает поры и не мешает естественному процессу потоотделения. 
            <br /><b>Без дискомфорта:</b> не ощущается на коже и не оставляет следов на одежде. Обладает лёгким свежим ароматом.
            <br /><b>Уход за кожей: </b>увлажняет и питает кожу подмышек.`,
    usage: 'Нанести на чистую сухую кожу подмышек с расстояния 10-15 см. Достаточно 2-3 пшиков на каждую подмышку.',
    ingredients: 'Aqua, Glycerin, Hydroxyethyl Urea, Panthenol, Citric Acid, Sodium Hyaluronate, AllInulin, Alpha-glucanoligosaccharide, Vitis vinifera (grape) seed oil, antoin, Niacinamide, Caffeine, Benzyl Alcohol, Chlorphenesin.',
    // image: images['deo.png'],
    volume: '100 мл',
    preview1: preview['gold_apple.png'],
    preview2: preview['gold_apple.png'],
    link: "https://goldapple.ru/brands/fromthemoon"
  },
  '/letoile': {
    title:`Лэтуаль`,
    text: `<b>Новый подход:</b> создаёт среду, в которой не выживают бактерии, вызывающих запах. 
            Не забивает поры и не мешает естественному процессу потоотделения. 
            <br /><b>Без дискомфорта:</b> не ощущается на коже и не оставляет следов на одежде. Обладает лёгким свежим ароматом.
            <br /><b>Уход за кожей: </b>увлажняет и питает кожу подмышек.`,
    usage: 'Нанести на чистую сухую кожу подмышек с расстояния 10-15 см. Достаточно 2-3 пшиков на каждую подмышку.',
    ingredients: 'Aqua, Glycerin, Hydroxyethyl Urea, Panthenol, Citric Acid, Sodium Hyaluronate, AllInulin, Alpha-glucanoligosaccharide, Vitis vinifera (grape) seed oil, antoin, Niacinamide, Caffeine, Benzyl Alcohol, Chlorphenesin.',
    // image: images['deo.png'],
    volume: '100 мл',
    preview1: preview['letoile.png'],
    preview2: preview['letoile.png'],
    link: "https://www.letu.ru/brand/fromthemoon"
  }
}